.about-page {
    padding: 50px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;

    .half {
        width: calc(70% - 40px);
        display: inline-block;
        vertical-align: top;
        padding: 20px;
        text-align: left;

        p {
            color: $mainfontcolor;
        }
    
        img {
            width: 75%;
        }

        

@media(max-width: 750px){
    width: 100%;
}
    }
}