.news-page {
    text-align: center;

    .news-container {
        width: 100%;
        text-align: center;

        .news-block {
            display: inline-block;
            box-shadow: 0px 2px 5px #888888;
            margin: 10px;
            padding: 10px;
            width: 100px;
            height: 100px;
            vertical-align: top;

            .a {
                vertical-align: top;
            }

            p {
                vertical-align: bottom;
            }
        }

        h3 {
            font-size: 16px;
        }
    }

    .news-text {
        text-align: left;
        max-width: 1000px;
        margin: 0 20%;
    }

    @media(max-width: 750px){
        .news-text {
            margin: 0 10%;
        }
    }
}